import React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import countries from "../countries.json"
import { User } from '../sdk/user';
import {Modal} from "./../helpers/Modal"
const ComingSoon = () => {
    const [state,setState]=useState({
        userType:"user",
        companyTypes:[
        "Бизнес-коуч / Консультант",
        "Лайф-коуч / Консультант",
        "Health-коуч / Фитнес-коуч",
        "Фэшн-блогер / Стилист / Консультантt",
        "Другое (укажите свое"
    ],
    companyForm:{
        companyName:"",
        companyEmail:"",
        companyType:"",
        country:"",
        tellUsWhyJoin:"",
    },
    userForm:{
        firstName:"",
        lastName:"",
        email:"",
        country:"",
        tellUsWhyJoin:"",
    },
    newCompanyType:"",
    buttonAttr:{
        disabled:false,
        loader:false,
    },
    label:{
        company:{
        companyName:"",
        companyEmail:"",
        tellUsWhyJoin:"",
        },
        user:{
        firstName:"",
        lastName:"",
        email:"",
        tellUsWhyJoin:"",
        }
    }
    })
    const handler = (event) => {
     let key = event.target.name;
     let value = event.target.value;
     if(key==="userType"){
        setState(pre=> {
            return {...pre,userType:value}
        })
     }
     else if(key==="companyType" && value==="other"){
        state.companyForm.companyType="";
        setState((pre)=>{
            return {...pre,...state}
        })
        Modal.showTab("#addNewComapnyType");
     }
     else if(key==="newCompanyType"){
        setState((pre)=>{
            return {...pre,newCompanyType:value}
        })
     }
     else{
        state[`${state.userType}Form`][key]=value;
        if(key !=="country" && key !=="companyType"){
            if(value.length){
            state.label[`${state.userType}`][key]="active";
            }
            else{
            state.label[`${state.userType}`][key]="";
            }
        }
       
        setState((pre)=>{
            return {...pre,...state}
        })
     }
    }
    const addNewType=(event)=>{
    event.preventDefault();
    if(state.newCompanyType !==""){
        state.companyTypes.push(state.newCompanyType);
        state.companyForm.companyType=state.newCompanyType;
        setState((pre)=>{
            return {...pre,...state}
        })
        Modal.hideTab(".modal")
    }
    }
    const onSubmit=async(event)=>{
        try{
            setState((pre)=>{
                return {...pre,buttonAttr:{disabled:true,loader:true}}
            })
            event.preventDefault();
            await new User().commingSoon({...state[`${state.userType}Form`],userType:state.userType});
            Modal.showTab("#exampleModal");
            setState((pre)=>{
                return {...pre,buttonAttr:{disabled:false,loader:false}}
            })
        }catch(e){
              console.log(e);
        }
    }
    
    return (<>
        <header>
            <nav className='navbar coming-soon'>
                <div className="container justify-content-center">
                    <Link to="/"><img src="/assets/images/comingsoon/logo.svg" alt="" /></Link>
                </div>
            </nav>
        </header>
        <section className='comingsoon-forms'>
            <div className='container'>
                <div className='section-titles text-center'>
                    <h1>Зарегистрируйтесь в списке ожидания</h1>
                    {state.userType==="company" &&  <p>Если вы или ваша организация помогает людям с ростом и развитием бизнеса, учит гармоничному распределению энергии и времени для достижения профессионального и личностного успеха – мы будем рады если вы присоединитесь к нам</p>}
                        {state.userType==="user" && <p>
                        Находите, добавляйте и делитесь ресурсами для роста и развития бизнеса, повышения уровня мотивации, выстраиванию личностной стратегии здорового образа жизни, реализации собственного потенциала.
                            </p>}
                   
                </div>
                <div className='join-us'>
                    <h2>Присоединяйтесь к нам</h2>
                   
                        {/* User Type */}
                        <div className='row user-type'>
                            <div className="col-auto">
                                <div class="form-check position-relative">
                                    <input class="form-check-input" type="radio" name='userType' value="user"onChange={handler} checked={state.userType==="user"}/>
                                    <label class="form-check-label" for="asUser">
                                    Как пользователь
                                    </label>
                                </div>
                            </div>
                            <div className="col-auto">
                                <div class="form-check position-relative">
                                    <input class="form-check-input" type="radio" name='userType' value="company" onChange={handler} checked={state.userType==="company"}/>
                                    <label class="form-check-label" for="asCompany">
                                    Создать бизнес-аккаунт
                                    </label>
                                </div>
                            </div>
                        </div>
                        {/* fields */}
                        {state.userType==="user" &&  <form onSubmit={onSubmit}>
                        <div className="fields">
                            <p>Наименование бизнес-организации</p>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="fieldset">
                                        <input type="text" class="form-control" name="firstName"  onChange={handler} value={state.userForm.firstName} required/>
                                        <label for="comapanyName" className={`${state.label.user.firstName}`}>Имя</label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="fieldset">
                                        <input type="text" class="form-control" name="lastName"  onChange={handler} value={state.userForm.lastName} required/>
                                        <label for="comapanyName" className={`${state.label.user.lastName}`}>Фамилия</label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="fieldset">
                                        
                                        <input type="email" class="form-control" name="email" onChange={handler} value={state.userForm.email} required/>
                                        <label for="comapanyEmail" className={`${state.label.user.email}`}>E-mail</label>                 
                                </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="fieldset">
                                        <label for="country" className="select_label">Страна</label>
                                        <select class="form-select" aria-label="Default select example" name="country" value={state.userForm.country} onChange={handler} required>
                                            <option value="" selected={true}>Выберете страну</option>
                                            {countries.map((val,index)=>{
                                                return (<>
                                                <option key={index} value={val.name}>{val.name}</option>
                                                </>)
                                            })}
                                        
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="fieldset">
                                        <input type="text" class="form-control mt-0" name="tellUsWhyJoin"  onChange={handler} value={state.userForm.tellUsWhyJoin}/>
                                        <label for="address" className={`${state.label.user.tellUsWhyJoin}`}>Почему вы хотите присоединиться?</label>  
                                    </div>
                                </div>
                        
                                <div className="col-md-6">
                                    <button type="submit" class="btn fields-btn" disabled={state.buttonAttr.disabled}>Зарегистрироваться{(state.buttonAttr.loader)?<div className="spinner-border"style={{width:"1rem",height:"1rem"}}></div>:""}</button>
                                </div>
                            </div>
                        </div>
                    </form>}
                    {state.userType==="company" &&  
                    <form onSubmit={onSubmit}>
                        <div className="fields">
                            <p>Наименование бизнес-организации</p>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="fieldset">
                                        <input type="text" class="form-control" name="companyName"  onChange={handler} value={state.companyForm.comapanyName} required/>
                                        <label for="comapanyName" className={`${state.label.company.companyName}`}>Наименование бизнес-организации</label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="fieldset">
                                        
                                        <input type="email" class="form-control" name="companyEmail"  onChange={handler} value={state.companyForm.comapanyEmail} required/>
                                        <label for="comapanyEmail" className={`${state.label.company.companyEmail}`}>E-mail</label>                 
                                </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="fieldset">
                                        <select class="form-select" aria-label="Default select example" name="companyType" value={state.companyForm.companyType} onChange={handler} required>
                                            <option value="" selected>Введите тип бизнес-организации</option>
                                           {state.companyTypes.map((val,index)=>{
                                            return(<>
                                            <option key={"type"+index} value={val}>{val}</option>
                                            </>)
                                            
                                           })}
                                            <option value="other">Введите тип бизнес-организации</option>
                                        </select>
                                        <label for="comapanyType" className="select_label">Тип бизнес-организации</label> 
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="fieldset">
                                        <label for="country" className="select_label">Страна</label>
                                        <select class="form-select" aria-label="Default select example" name="country" value={state.companyForm.country} onChange={handler} required>
                                            <option value="" selected={true}>Выберете страну</option>
                                            {countries.map((val,index)=>{
                                                return (<>
                                                <option key={index} value={val.name}>{val.name}</option>
                                                </>)
                                            })}
                                        
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="fieldset">
                                        <input type="text" class="form-control mt-0" name="tellUsWhyJoin"  onChange={handler} value={state.companyForm.tellUsWhyJoin}/>
                                        <label for="address" className={`${state.label.user.tellUsWhyJoin}`}>Почему вы хотите присоединиться?</label>  
                                    </div>
                                </div>
                        
                                <div className="col-md-6">
                                    <button type="submit" class="btn fields-btn" disabled={state.buttonAttr.disabled}>Зарегистрироваться{(state.buttonAttr.loader)?<div className="spinner-border"style={{width:"1rem",height:"1rem"}}></div>:""}</button>
                                </div>
                            </div>
                        </div>
                    </form>}
                       
                   
                </div>
            </div>
        </section>
        <footer>
            <div style={{background: "var(--ui-grey)",paddingBottom:"15px"}}>
                <div className="container">
                    <div className="row justify-content-between">
                  <div className="col-12 footer-bar text-center">
                            Copyright 2022 all rights are reserved
                        </div>
                    </div>
                </div>
            </div>
        </footer>

{/* <!-- Modal --> */}
<div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog">
    <div className="modal-content">
      {<div className="modal-header">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div> }
      <div className="modal-body text-center">
      <Link to="/"><img src="/assets/images/comingsoon/popup-logo.svg" alt="" /></Link>
      <h2>Спасибо, что присоединился <br/>к нам</h2>
      </div>
    </div>
  </div>
</div>
<div className="modal fade" id="addNewComapnyType" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog">
    <div className="modal-content">
      {<div className="modal-header">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
       </div> }
      <div className="modal-body text-center fields">
        <form onSubmit={addNewType}>
      <div className="row">
        <div className="col-12 mb-4">
            <p style={{color:"#70747D"}}>Пожалуйста, укажите тип вашей бизнес-организации</p>
        </div>
      <div className="col-12 mt-2">
      <div className="fieldset">
      <input type="text" class="form-control" name="newCompanyType" placeholder="Enter Company type" onChange={handler} value={state.newCompanyType} required/>
     </div>
      </div>
      <div className="col-12">
        <button type="submit" class="btn fields-btn">Done</button>
      </div>

      </div>
      </form>
      </div>
    </div>
  </div>
</div>
    </>)
}

export default ComingSoon;