import { Own_User,My_Post_Action } from "../ActionConstants"
let intialState={
    data:null,
    error:null,
    friendShipStatus:{
        followers:[],
        followings:[],
        followBack:[],

    }

}
export const ownUserReducer=(state=intialState,{type,payload})=>{
switch (type) {
    case Own_User.success:
    return {...state,data:payload.data,friendShipStatus:payload.friendShipStatus}
    case My_Post_Action.success:
    return {...state,data:payload.data}
    case Own_User.error:
    return {...state,error:payload}
    default:
    return state
}

}