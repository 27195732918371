import React from 'react';
import { Routes,Route} from "react-router-dom";
import  {PublicRoutes}  from './PublicRoutes';
import ComingSoon from '../Components/ComingSoon';


const Routers = () => {
    return (<>
        <Routes>
           
            <Route element={<PublicRoutes/>}>
            <Route exact path='/' element={<ComingSoon />} />
            </Route>
            </Routes>
    </>);
}

export default Routers;
