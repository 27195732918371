export const Loader=()=>{
    return(
        <>
        <div className="site-loader">
        <span></span>
        <span></span>
    </div>
    </>
    ) 
    
}
