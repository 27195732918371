export const getFriendShipStatus=(data,userId)=>{
    let friendStatus={
        followers:[],
        followings:[],
        followBack:[],
    }
    let followings=(Object.keys(data).length)?data.followings : [];
    let followers=(Object.keys(data).length)?data.followers : [];
    
    friendStatus.followings=followings.map((val)=>{
        
        return val.follow_to;
    })
    
    if(followers.length){
        for(const val of followers){
            if(val.status===2 && val.follow_to===userId){
                 friendStatus.followBack.push(val.follow_by);
            }
            else{
                friendStatus.followers.push(val.follow_by);
            }
        }
    }
   return friendStatus
    }