import Routers from './Routes/Routers';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Popups from './Components/Popups';
import { useCheckAuth } from './customHooks';
import { useEffect } from 'react';
import Header from './Components/Header';
import { useAuthContext } from './context';
import { Modal } from './helpers';
import { ToasterComponent } from './Components/DefaultComponents';
import { useLocation } from 'react-router-dom'
import Footer from './Components/Footer';
function App() {
const [checkAuth]=useCheckAuth();
const userAuth=useAuthContext();
const location=useLocation();
const checkEmailVerfication=()=>{
  if(userAuth.isAuthenticated && !userAuth.user.emailVerified){
    Modal.showTab("#successPopup");
  }
}
useEffect(()=>{
  let cronJob= setInterval(() => {
    checkAuth();
    checkEmailVerfication()
   },3000); 
   
  return ()=>clearTimeout(cronJob)
},[checkAuth])

  return (<>
   
    
    <Routers />
    
    
    
    <ToasterComponent />
    
    
    
    
    
    
  </>);
}

export default App;